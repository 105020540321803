.RedocPage-header {
  position: fixed;
  top: 0;
  padding: 10px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-bottom: 2px solid #f5f5f4;
  z-index: 999;
}

.RedocPage-header a {
  display: flex;
  color: #1E0338;
  text-decoration: none;
  align-items: center;
}

.RedocPage-header h1 {
  margin: 4px 0 0 16px;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Poppins';
}

.RedocPage-header img {
  height: 30px;
  width: auto;
}

.RedocPage-header .select-containers {
  display: flex;
  align-items: center;
}

.RedocPage-header .select__api {
  max-width: 50vw;
  width: 400px;
  padding-right: 20px;
}

section.container__redoc {
  padding-top: 60px;
}
